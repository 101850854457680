import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ActiveStep,
  AssetGroupAndAsset,
  CreateGroup,
  RecurringReport,
  ReportCategory,
  ReportType,
  RequiredFields,
  SelectedPlant,
  ShiftsPerDay,
  User,
  View,
  WeekendOperation,
  initReqFieldsState,
} from '../models/reportsModels';
import { RootState } from 'app/store';
import { Template } from 'api/reports/useGetTemplates';
import { initTemplate } from '../components/create-report/templates/SelectTemplate';

export interface ReportState {
  view : View;
  reportCategory : ReportCategory;
  selectedRecurringReports : RecurringReport[];
  activeStep : ActiveStep;
  assets : AssetGroupAndAsset[];
  imageUrl : string;
  generateStandardReport : undefined | 'all' | 'unhealthy';
  // new report
  reportType : ReportType;
  selectedPlant : SelectedPlant | null;
  selectedAssets : AssetGroupAndAsset[];
  customGroups : CreateGroup[];
  recipients : User[];
  audience : User[];
  unit : string;
  fileFormat : string;
  drawDescriptionFields : boolean;
  assetGroupHeadings : boolean;
  includeAssetImages : boolean;
  includeThreeAxisOnChar : boolean;
  reportName : string;
  reportFileName : string;
  selectedTemplate : Template;

  requiredFields: RequiredFields;
  templates : Template[];
  schedule: string;

  isReportEdit : boolean;
  language : string;
  selectedOption : string;
  customValue : string;
  weekendOperation : WeekendOperation;
  shiftsPerDay : ShiftsPerDay;
  plantUptime : number;
  isTemplateOverwrite: boolean | null;

  cartridgePlants : boolean;
}

export const initialState : ReportState = {
  view: 'reports',
  reportCategory: 'Standard',
  selectedRecurringReports: [],
  activeStep: 0,
  assets: [],
  imageUrl: `${process.env.REACT_APP_BLOB_IMAGE_STORAGE}/public/reports/plant_report_default_img.png`,
  generateStandardReport: undefined,

  reportType: 'Standard',
  selectedPlant: null,
  selectedAssets: [],
  customGroups: [],
  recipients: [],
  audience: [],
  unit: 'metric',
  fileFormat: 'docx',
  drawDescriptionFields: false,
  assetGroupHeadings: false,
  includeAssetImages: false,
  includeThreeAxisOnChar: false,
  reportName: '',
  reportFileName: '',
  selectedTemplate: initTemplate,
  requiredFields: initReqFieldsState,
  templates: [],
  schedule: '',

  isReportEdit: false,
  language: 'EN',
  selectedOption: '7',
  customValue: '',
  weekendOperation: 'none',
  shiftsPerDay: '1x8',
  plantUptime: 0,
  isTemplateOverwrite: false,

  cartridgePlants: false,
};

export const newReportSlice = createSlice({
  name: 'newReport',
  initialState,
  reducers: {
    setView: (state, action) => {
      state.view = action.payload;
    },
    setReportCategory: (state, action) => {
      state.reportCategory = action.payload;
    },
    setRecurringReports: (state, action) => {
      state.selectedRecurringReports = action.payload;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setReportType: (state, action) => {
      state.reportType = action.payload;
    },
    setSelectedPlant: (state, action) => {
      state.selectedPlant = action.payload;
    },
    setSelectedAssets: (state, action) => {
      state.selectedAssets = action.payload;
    },
    setCustomGroups: (state, action) => {
      state.customGroups = action.payload;
    },
    setRecipients: (state, action) => {
      state.recipients = action.payload;
    },
    setAudience: (state, action) => {
      state.audience = action.payload;
    },
    setUnit: (state, action) => {
      state.unit = action.payload;
    },
    setFileFormat: (state, action) => {
      state.fileFormat = action.payload;
    },
    setDrawDescriptionFields: (state, action) => {
      state.drawDescriptionFields = action.payload;
    },
    setAssetGroupHeadings: (state, action) => {
      state.assetGroupHeadings = action.payload;
    },
    setIncludeAssetImages: (state, action) => {
      state.includeAssetImages = action.payload;
    },
    setIncludeThreeAxisOnChar: (state, action) => {
      state.includeThreeAxisOnChar = action.payload;
    },
    setReportName: (state, action) => {
      state.reportName = action.payload;
    },
    setReportFileName: (state, action) => {
      state.reportFileName = action.payload;
    },
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload;
    },
    setAssets: (state, action) => {
      state.assets = action.payload;
    },
    setRequiredFields: (state, action) => {
      state.requiredFields = action.payload;
    },
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },
    setSchedule: (state, action) => {
      state.schedule = action.payload;
    },
    setIsReportEdit: (state, action) => {
      state.isReportEdit = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    setCustomValue: (state, action) => {
      state.customValue = action.payload;
    },
    setWeekendOperation: (state, action : PayloadAction<WeekendOperation>) => {
      state.weekendOperation = action.payload;
    },
    setShiftsPerDay: (state, action : PayloadAction<ShiftsPerDay>) => {
      state.shiftsPerDay = action.payload;
    },
    setPlantUptime: (state, action : PayloadAction<number>) => {
      state.plantUptime = action.payload;
    },
    setGenerateStandardReport: (state, action : PayloadAction<undefined | 'all' | 'unhealthy'>) => {
      state.generateStandardReport = action.payload;
    },
    setImageUrl: (state, action : PayloadAction<string>) => {
      state.imageUrl = action.payload;
    },
    setIsTemplateOverwrite: (state, action) => {
      state.isTemplateOverwrite = action.payload;
    },
    setCartridgePlants: (state, action) => {
      state.cartridgePlants = action.payload;
    },
  },
});

export const {
  setView,
  setReportCategory,
  setRecurringReports,
  setActiveStep,
  setReportType,
  setSelectedPlant,
  setSelectedAssets,
  setCustomGroups,
  setRecipients,
  setAudience,
  setUnit,
  setFileFormat,
  setAssetGroupHeadings,
  setDrawDescriptionFields,
  setIncludeAssetImages,
  setIncludeThreeAxisOnChar,
  setReportName,
  setReportFileName,
  setSelectedTemplate,
  setAssets,
  setRequiredFields,
  setTemplates,
  setSchedule,
  setIsReportEdit,
  setLanguage,
  setSelectedOption,
  setCustomValue,
  setImageUrl,
  setGenerateStandardReport,
  setPlantUptime,
  setShiftsPerDay,
  setWeekendOperation,
  setIsTemplateOverwrite,
  setCartridgePlants,
} = newReportSlice.actions;

export const selectView = (state : RootState) => state.newReport.view;
export const selectReportCategory = (state : RootState) => state.newReport.reportCategory;
export const selectRecurringReports = (state : RootState) =>
  state.newReport.selectedRecurringReports;
export const selectActiveStep = (state : RootState) => state.newReport.activeStep;
export const selectReportType = (state : RootState) => state.newReport.reportType;
export const selectSelectedPlant = (state : RootState) => state.newReport.selectedPlant;
export const selectSelectedAssets = (state : RootState) => state.newReport.selectedAssets;
export const selectCustomGroups = (state : RootState) => state.newReport.customGroups;
export const selectRecipients = (state : RootState) => state.newReport.recipients;
export const selectAudience = (state : RootState) => state.newReport.audience;
export const selectUnit = (state : RootState) => state.newReport.unit;
export const selectFileFormat = (state : RootState) => state.newReport.fileFormat;
export const selectDrawDescriptionFields = (state : RootState) =>
  state.newReport.drawDescriptionFields;
export const selectAssetGroupHeadings = (state : RootState) => state.newReport.assetGroupHeadings;
export const selectIncludeAssetImages = (state : RootState) => state.newReport.includeAssetImages;
export const selectIncludeThreeAxisOnChar = (state : RootState) =>
  state.newReport.includeThreeAxisOnChar;
export const selectReportName = (state : RootState) => state.newReport.reportName;
export const selectReportFileName = (state : RootState) => state.newReport.reportFileName;
export const selectSelectedTemplate = (state : RootState) => state.newReport.selectedTemplate;
export const selectAssets = (state : RootState) => state.newReport.assets;
export const selectRequiredFields = (state : RootState) => state.newReport.requiredFields;
export const selectTemplates = (state : RootState) => state.newReport.templates;
export const selectSchedule = (state : RootState) => state.newReport.schedule;
export const selectIsReportEdit = (state : RootState) => state.newReport.isReportEdit;
export const selectLanguage = (state : RootState) => state.newReport.language;
export const selectSelectedOption = (state : RootState) => state.newReport.selectedOption;
export const selectCustomValue = (state : RootState) => state.newReport.customValue;
export const selectImageUrl = (state : RootState) => state.newReport.imageUrl;
export const selectGenerateStandardReport = (state : RootState) =>
  state.newReport.generateStandardReport;
export const selectWeekendOperation = (state : RootState) => state.newReport.weekendOperation;
export const selectShiftsPerDay = (state : RootState) => state.newReport.shiftsPerDay;
export const selectPlantUptime = (state : RootState) => state.newReport.plantUptime;
export const selectIsTemplateOverwrite = (state : RootState) => state.newReport.isTemplateOverwrite;
export const selectCartridgePlants = (state : RootState) => state.newReport.cartridgePlants;

export default newReportSlice.reducer;
