import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FC, useEffect, useState } from 'react';
import { Template, useGetTemplates } from 'api/reports/useGetTemplates';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import { Box, Divider } from '@mui/material';

import {
  initialState,
  selectAssets,
  selectIsReportEdit,
  selectIsTemplateOverwrite,
  selectRecurringReports,
  selectReportCategory,
  selectReportType,
  selectSelectedTemplate,
  selectTemplates,
  setAssetGroupHeadings,
  setAudience,
  setCustomGroups,
  setCustomValue,
  setDrawDescriptionFields,
  setFileFormat,
  setGenerateStandardReport,
  setImageUrl,
  setIncludeAssetImages,
  setIncludeThreeAxisOnChar,
  setIsTemplateOverwrite,
  setPlantUptime,
  setRecipients,
  setSelectedAssets,
  setSelectedOption,
  setSelectedPlant,
  setSelectedTemplate,
  setShiftsPerDay,
  setTemplates,
  setUnit,
  setWeekendOperation,
} from 'features/new-reports/store/newReportSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetTemplate } from 'api/reports/useGetTemplate';
import { setActiveEndDate, setActiveStartDate } from 'store/accountSlice';
import NewTemplate from './NewTemplate';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import {
  MaintenanceTemplateBody,
  PlantTemplateBody,
  StandardTemplateBody,
} from 'features/new-reports/models/reportsModels';

export const initTemplate = {
  id: 0,
  name: 'New Template 1*',
  createdOn: new Date().toLocaleTimeString(),
};

type Props = {
  selectedPlantId : number | null;
  clearConfigurationData : () => void;
};
const SelectTemplate : FC<Props> = ({ selectedPlantId, clearConfigurationData }) => {
  const dispatch = useDispatch();

  const isReportEdit = useSelector(selectIsReportEdit);
  const selectedReports = useSelector(selectRecurringReports);
  const reportType = useSelector(selectReportType);
  const reportCategory = useSelector(selectReportCategory);

  const templates = useSelector(selectTemplates);
  const selectedTemplate = useSelector(selectSelectedTemplate);
  const assets = useSelector(selectAssets);

  const { data, isLoading } = useGetTemplates(selectedPlantId, reportType);

  const { data: templateData } = useGetTemplate(selectedTemplate.id ?? 0);
  const [newTemplate, setNewTemplate] = useState<Template | null>(null);
  const [openNewTemplate, setOpenNewTemplate] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const overwrite = useSelector(selectIsTemplateOverwrite);

  useEffect(() => {
    data && !isLoading ? dispatch(setTemplates(data)) : dispatch(setTemplates([]));
  }, [data, isLoading]);

  useEffect(() => {
    if (isReportEdit && selectedReports.length && templates.length) {
      const template = templates.filter((d) => d.id === selectedReports[0].templateId)?.[0];
      if (template) {
        dispatch(setSelectedTemplate(template));
      } else {
        toast.error('The full report data cannot be retrieved as the template has been deleted');
      }
    }
  }, [templates, isReportEdit, selectedReports]);

  const fillTemplateDetails = (
    templateData : StandardTemplateBody | PlantTemplateBody | MaintenanceTemplateBody,
  ) => {
    if (templateData.type === 'Standard') {
      const {
        recipients,
        reportTimeSpan,
        unit,
        threeAxisEnable,
        assetImages,
        assetGroupHeadings,
        fileFormat,
        audience,
        assetIds,
        assetGroups,
        descriptionFields,
      } = templateData.settings;
      recipients &&
        dispatch(
          setRecipients(recipients.map((r, i) => ({ ...r, position: r.jobPosition, id: ++i }))),
        );
      audience &&
        dispatch(setAudience(audience.map((r, i) => ({ ...r, position: r.jobPosition, id: ++i }))));
      assetIds &&
        dispatch(setSelectedAssets(assets.filter((a) => assetIds.some((id) => id === a.id))));
      assetGroups &&
        dispatch(
          setCustomGroups(
            assetGroups.map((g, i) => ({
              id: ++i,
              groupName: g.name,
              desc: g.description,
              assets: assets.filter((a) => g.assetIds.some((id) => id === a.id)),
            })),
          ),
        );
      dispatch(setActiveStartDate(moment().subtract(reportTimeSpan, 'd').toISOString()));
      dispatch(setActiveEndDate(moment().toISOString()));
      dispatch(setUnit(unit));
      dispatch(setIncludeThreeAxisOnChar(threeAxisEnable));
      dispatch(setIncludeAssetImages(assetImages));
      dispatch(setAssetGroupHeadings(assetGroupHeadings));
      dispatch(setFileFormat(fileFormat));
      dispatch(setDrawDescriptionFields(descriptionFields));
      dispatch(
        setSelectedOption(
          reportTimeSpan !== 7 && reportTimeSpan !== 14 && reportTimeSpan !== 30
            ? 'custom'
            : String(reportTimeSpan),
        ),
      );
      dispatch(setCustomValue(String(reportTimeSpan)));
    }

    if (templateData.type === 'Plant') {
      const {
        recipients,
        plantId,
        plantName,
        reportTimeSpan,
        unit,
        threeAxisEnable,
        weekendOperations,
        imageURL,
        shifts,
        assetImages,
        assetGroupHeadings,
        descriptionFields,
        fileFormat,
        plantUptime,
        generateStandardReport,
        standardReportAllAssets,
      } = templateData.settings;

      dispatch(
        setRecipients(recipients.map((r, i) => ({ ...r, position: r.jobPosition, id: ++i }))),
      );
      dispatch(setSelectedPlant({ plantId: plantId, plantName: plantName }));
      dispatch(setActiveStartDate(moment().subtract(reportTimeSpan, 'd').toISOString()));
      dispatch(setActiveEndDate(moment().toISOString()));
      dispatch(setUnit(unit));
      dispatch(setIncludeThreeAxisOnChar(threeAxisEnable));
      dispatch(setWeekendOperation(weekendOperations));
      dispatch(setImageUrl(imageURL === null ? initialState.imageUrl : imageURL));
      dispatch(setShiftsPerDay(shifts));
      dispatch(setIncludeAssetImages(assetImages));
      dispatch(setAssetGroupHeadings(assetGroupHeadings));
      dispatch(setDrawDescriptionFields(descriptionFields));
      dispatch(setFileFormat(fileFormat));
      dispatch(setPlantUptime(plantUptime));
      dispatch(
        setGenerateStandardReport(
          generateStandardReport && standardReportAllAssets
            ? 'all'
            : !standardReportAllAssets
            ? 'unhealthy'
            : undefined,
        ),
      );

      if (reportCategory === 'Recurring') {
        const isCustom = reportTimeSpan !== 7 && reportTimeSpan !== 14 && reportTimeSpan !== 30;
        dispatch(setSelectedOption(isCustom ? 'custom' : String(reportTimeSpan)));
        if (isCustom) dispatch(setCustomValue(String(reportTimeSpan)));
      }
    }

    if (templateData.type === 'Maintenance') {
      const { recipients } = templateData.settings;

      dispatch(
        setRecipients(recipients.map((r, i) => ({ ...r, position: r.jobPosition, id: ++i }))),
      );
    }
  };
  useEffect(() => {
    if (templateData && selectedTemplate.name === templateData.name && !overwrite) {
      fillTemplateDetails(templateData);
    } else if (overwrite) {
      setOpenNewTemplate(false);
      handleClose();
    } else {
      clearConfigurationData();
    }
  }, [templateData, assets]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (newTemplate && newTemplate.name !== '') {
      dispatch(setSelectedTemplate(newTemplate));
      handleClose();
    }
  }, [newTemplate]);

  useEffect(() => {
    if (selectedTemplate.id !== 0 && !overwrite) {
      setNewTemplate(null);
    }
  }, [selectedTemplate, overwrite]);

  useEffect(() => {
    dispatch(setSelectedTemplate(initTemplate));
    setNewTemplate(null);
  }, [selectedPlantId]);

  const open = Boolean(anchorEl);

  const handleClick = (event : React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (item : Template) => {
    dispatch(setSelectedTemplate(item));
    overwrite && dispatch(setIsTemplateOverwrite(false));
    handleClose();
  };

  return (
    <Box minWidth='13.4rem'>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        variant='outlined'
        color='secondary'
        sx={{ color: 'black', fontWeight: '600' }}
        fullWidth
      >
        {selectedTemplate.name}
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem key={initTemplate.id} onClick={() => handleSelect(initTemplate)}>
          {initTemplate.name}
        </MenuItem>
        {selectedPlantId && templates.length
          ? templates.map((item) => (
              <MenuItem key={item.id} onClick={() => handleSelect(item)}>
                {item.name}
              </MenuItem>
            ))
          : ''}
        {newTemplate && (
          <MenuItem key={newTemplate.id} onClick={() => handleSelect(newTemplate)}>
            {newTemplate.name}
          </MenuItem>
        )}
        <Divider />
        <Box display='flex' justifyContent='center' p={1}>
          <Button
            variant='contained'
            color='secondary'
            sx={{ color: 'black', fontWeight: '600' }}
            startIcon={<AddIcon />}
            size='small'
            onClick={() => setOpenNewTemplate(true)}
          >
            Create new template
          </Button>
        </Box>
      </Menu>
      {openNewTemplate && (
        <NewTemplate
          open={openNewTemplate}
          onClose={() => setOpenNewTemplate(false)}
          setNewTemplate={setNewTemplate}
          takenNames={templates.length ? templates.map((d) => d.name) : []}
          fillTemplateDetails={fillTemplateDetails}
        />
      )}
    </Box>
  );
};

export default SelectTemplate;
